@import '__importable.scss';
.richMedia {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	width: 100%;
	background-color: $gray-1;

	&BuildFlow {
		height: auto;
	}

	&Horizontal {
		width: 12.8rem;
		height: auto;
	}
	&Large {
		width: 12.8rem;
	}

	&Vertical {
		flex: 1;
	}

	&LineItem {
		width: 7.2rem;
		height: auto;
		flex: unset;
	}

	&WithBigImage {
		width: auto;
		height: auto;
		max-height: 15.2rem;
		flex: unset;

		@media screen and (min-width: $media-sm) {
			width: 30.4rem;
		}
	}

	&InDrawer {
		width: 100%;
	}

	&Img {
		object-fit: contain;
		mix-blend-mode: multiply;
		width: 100%;

		&Top {
			@include top-frame-shadow;
		}

		&Accessory {
			height: 100%;
			object-fit: cover;
			border-radius: 0;
			// Fixes Safari "Octagon" Shape
			// Semi-relevant context around why the issue occurs here: https://stackoverflow.com/questions/17202128/rounded-cornes-border-radius-safari-issue
			clip-path: none !important;
		}

		&SearchAccessory {
			object-fit: cover;
			border-radius: 0;
			clip-path: none !important;
		}

		&GiftCard {
			padding: 10%;
		}

		&LineItem {
			width: 7.2rem;
		}
	}
	.saveTag {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1000;
	}
}

.swiper-container {
	height: 100%;
	width: 100%;

	img {
		height: 100%;
	}
}

.slide-container {
	background-color: $gray-1;
}

.swiper-pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	transition: 300ms ease opacity;
	transform: translate3d(0, 0, 0);
	bottom: 0.5rem;

	&::after {
		content: "";
		height: 2rem;
		width: 6rem;
		position: absolute;
		border-radius: 1.6rem;
		background: $gray-1;
		opacity: 50%;
	}

	&.swiper-pagination-hidden {
		opacity: 0;
	}

	@media screen and (min-width: $media-lg) {
		display: none;
	}
}

.swiper-pagination-bullet {
	margin-inline: 0.4rem;
	height: 2.6rem;
	display: flex;
	justify-content: center;
	border-radius: $radius-6;
	align-items: center;
	position: relative;
	z-index: $zindex-2;

	&::after {
		content: "";
		width: 0.8rem;
		display: block;
		border-radius: $radius-6;
		height: 0.8rem;
		background: $blue-1;
		position: relative;
		opacity: 0.25;
		transition: width 200ms ease-in-out;
	}

	&:only-child {
		display: none;
	}

	&:focus-visible {
		box-shadow: $shadow-pink;
	}

	&.swiper-pagination-bullet-active::after {
		transition: width 200ms ease-in-out;
		width: 3.2rem;
		height: 0.8rem;
		border: none;
		opacity: inherit;
		background-color: $blue-1;
	}
}

.swiper-pagination-lock {
	display: none;
}

.swiper-pagination-bullet {
	cursor: pointer;
	z-index: $zindex-1;
}

.imgWrapper {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity 0.3s ease;
	}
}
